import React, { FC } from 'react';

import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import { PageContent } from '@shared/types/umbraco';
import BodyRenderer from 'gatsby-theme-husky/src/common/BodyRenderer';
import { Container, DangerouslySetInnerHtml, Layout } from 'layout';
import SearchInput from 'gatsby-theme-husky/src/common/SearchInput';
import InfoBanner from 'gatsby-theme-husky/src/components/InfoBanner';
import SignUp from 'gatsby-theme-husky/src/components/SignUp';
import './ErrorPage.scss';

const elements = {
  'Info Banner': ({ properties }, keyId) => <InfoBanner key={keyId} {...properties} />,
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
};

const ErrorPage: FC<PageContent.INotFoundPage> = ({
  page: { nodes },
  pageContext: { link, searchUrl },
}) => {
  const {
    titleAndDescription,
    background,
    troubleshootingGuide,
    defaultCompositions,
    seoMetaKeywords,
    seoMetaTitle,
    seoMetaDescription,
    seoExternalHreflangs,
    seoNoIndex,
    seoCanonicalUrl,
    searchPlaceholder,
    searchTitle,
    body,
    alternateUrls,
  } = nodes?.[0] || {};
  const errorPageBackground = background?.[0]?.properties;
  const { singupFormBaner, waiSettings } = defaultCompositions || {};

  return (
    <Layout
      {...{
        ...defaultCompositions,
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        seoNoIndex,
        seoCanonicalUrl,
        skipText: waiSettings?.skipText,
        link,
        searchUrl,
        alternateUrls,
      }}
    >
      <div className="error-page">
        <div className="error-page__main">
          {errorPageBackground?.image ? (
            <GatsbyImage image={errorPageBackground.image} alt={errorPageBackground?.imageAlt} />
          ) : null}
          <Container fluid>
            <div className="error-page__info">
              {titleAndDescription && (
                <DangerouslySetInnerHtml className="error-page__title" html={titleAndDescription} />
              )}
              <div className="error-page__search">
                <div className="search__title">{searchTitle}</div>
                <SearchInput searchPlaceholder={searchPlaceholder} searchUrl={searchUrl} />
              </div>
              <DangerouslySetInnerHtml
                className="error-page__troubleshooting"
                html={troubleshootingGuide}
              />
            </div>
          </Container>
        </div>
        {body?.length ? (
          <div className="home__body">
            <BodyRenderer
              bodyData={body}
              bodyStructure={elements}
              bodyItemProps={{ ...{ singupFormBaner } }}
            />
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default ErrorPage;
